.content {
  background-color: var(--body-color);
  overflow: hidden;

  svg {
    color: var(--white);
  }

  .topHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 1rem;

    .name {
      color: var(--white);
    }

    .link {
      display: flex;
      align-items: center;
      gap: 2rem;

      .title {
        height: 2rem;
        display: flex;
        align-items: center;

        img {
          height: 2rem;
          width: auto;
        }
      }
    }
  }

  .drawerList {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item {
      padding: 0.3rem 0;
      margin: 0 auto;
      display: flex;

      .bannersGames {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
          width: 95%;
          height: auto;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          height: 2rem;
          width: auto;
          object-fit: cover;

          svg {
            color: var(--white);
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .listText {
      color: var(--color-button);
    }
  }
}
