.drawerList {
  background-color: var(--body-color);
  // height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 9.2rem;
  padding: 0;

  .item {
    padding: 0.3rem 0;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        height: 2rem;
        width: auto;
        object-fit: cover;

        svg {
          color: var(--white);
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .listText {
    white-space: nowrap;
    color: var(--color-button);
  }
}
