.headerMobile {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .headerMobile {
        display: block;
        width: 100%;
        background-color: var(--header);
        padding: var(--headerThreePaadingMobile, 1.5rem 1rem);
        position: sticky;
        top: 0;
        z-index: 99997;

        .loginHeaderButtons {
            display: flex;
            gap: 0.5rem;
            width: 100%;

            .buttonNavigation {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                padding: 1rem 0;
                width: 100%;
                background-color: transparent;
                color: var(--yellow-color);
                border: 1px var(--yellow-color) solid;
                border-radius: 0.3rem;
            }

            .buttonNavigationTow {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                padding: 1rem 0;
                width: 100%;
                background-color: var(--yellow-color);
                color: var(--white);
                border: 1px var(--yellow-color) solid;
                border-radius: 0.3rem;
            }
        }

        .contentMobile {
            display: flex;
            align-items: center;
            justify-content: space-between;

            :global(.MuiPaper-root) {
                background-color: var(--body-color);
            }

            .logoMenu {
                .logo {
                    display: flex;
                    height: 100%;

                    img {
                        max-width: var(--logo-size-mobile, 15rem);
                        max-height: var(--logo-height-mobile, 33px);
                        cursor: pointer;
                    }
                }
            }

            .menuBtnGrp {
                display: flex;
                align-items: center;
                gap: 1rem;

                .languageWrap {
                    position: relative;

                    .flag {
                        position: absolute;
                        top: 4px;
                        left: 0;
                        width: 28px;
                        height: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .dashboarWrap {
                        display: flex;
                        gap: 20px;
                        align-items: center;

                        .dashboarSocial {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icons {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                text-align: center;
                                line-height: 33px;
                                background: var(--social-box);
                                position: relative;

                                svg {
                                    color: #1877f2;
                                    font-size: 2rem;
                                }

                                .count {
                                    width: 10px;
                                    height: 10px;
                                    text-align: center;
                                    line-height: 10px;
                                    background: var(--button-one);
                                    color: var(--white);
                                    display: block;
                                    border-radius: 50%;
                                    font-size: 8px;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }

                svg {
                    color: white;
                    font-size: 3rem;
                }
            }
        }

        .mainMenuMobile {
            position: absolute;
            // top: 100%;
            right: 0;
            left: 0;
            z-index: 7;

            background: var(--header-color);
            background-color: var(--header) !important;
            border-radius: 0 0 1rem 1rem;

            // overflow-y: auto;
            padding: 5px 5px;
            max-height: calc(100vh - 130px);

            transform-origin: top;
            transition: all 0.4s !important;

            li {
                background: transparent;
                border-bottom: 1px solid rgba(136, 182, 255, 0.3764705882);
                border-radius: 5px;

                // padding-left: 10px;

                a {
                    color: var(--white);
                    font-weight: var(--font-bold-light);
                    font-size: 16px;
                    transition: all 0.3s;
                    font-family: "Inter", sans-serif;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    gap: 9px;
                    padding: 10px 12px 11px;
                    font-size: var(--font-size-md);
                }
            }

            .btns {
                display: flex;
                align-items: center;
                gap: 1rem;

                margin-top: 1rem;

                button {
                    position: relative;
                    z-index: 1;

                    display: inline-block;

                    background: var(--yellow-color);
                    border: 0;
                    border-radius: 1rem;

                    color: var(--color-button);
                    cursor: pointer;

                    font-size: 1.4rem;
                    font-weight: var(--font-bold-light);

                    overflow: hidden;
                    padding: 6px 25px 8px;

                    text-transform: capitalize;
                    transition: all 0.3s;
                    text-align: center;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 0px;
                        transition: all 0.4s;
                    }

                    &:hover::before {
                        width: 100%;
                    }

                    span {
                        z-index: 1;
                        position: relative;
                        transition: all 0.4s;
                        display: block;
                    }
                }

                // .buttonNavigation {
                //     background: var(--button-one);

                //     &::before {
                //         background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
                //     }
                // }

                // .buttonNavigationTow {
                //     background: var(--button-two);

                //     &::before {
                //         background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
                //     }
                // }
            }
            .dashboarWrap {
                display: flex;
                gap: 20px;
                align-items: center;
                margin-top: 0.5rem;

                button {
                    display: inline-block;

                    background: var(--yellow-color);
                    border: 0;
                    border-radius: 1rem;

                    color: var(--color-button);
                    font-size: 1.4rem;
                    font-weight: var(--font-bold-light);

                    padding: 0.8rem 2rem;
                    text-transform: capitalize;
                    transition: all 0.3s;
                    text-align: center;
                    font-size: var(--font-size-md);
                }

                .items {
                    text-align: right;

                    .small {
                        font-size: 10px;
                        color: var(--white);
                        margin-bottom: 4px;
                        display: block;
                    }

                    h6 {
                        font-weight: var(--font-bold-light);
                        color: var(--white);
                        font-size: 14px;
                        line-height: 110%;
                    }

                    .button {
                        padding: 8px 20px 10px;
                        font-size: 14px;
                        font-weight: var(--font-bold-light);
                        display: inline-block;
                        text-transform: capitalize;
                        transition: all 0.3s;
                        text-align: center;
                        background: var(--button-one);
                        position: relative;
                        overflow: hidden;
                        border-radius: 10px;
                        z-index: 1;

                        &::before {
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 0px;
                            transition: all 0.4s;
                        }

                        &:hover::before {
                            width: 100%;
                        }

                        span {
                            z-index: 1;
                            position: relative;
                            transition: all 0.4s;
                        }

                        &::before {
                            background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
                        }
                    }

                    .customDropdown {
                        .customDropdownUser {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 33px;
                            background: var(--social-box);

                            .icons {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                text-align: center;
                                line-height: 33px;
                                background: var(--social-box);
                                position: relative;

                                svg {
                                    color: var(--white);
                                    font-size: 2rem;
                                }
                            }
                        }
                    }
                }

                .selectMobile {
                    position: relative;

                    .flag {
                        position: absolute;
                        top: 4px;
                        left: 0;
                        width: 28px;
                        height: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
