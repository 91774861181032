.header {
  width: 100%;
  background-color: var(--background-color-header);
  padding: 0 0 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid var(--color-red);
  min-height: var(--headerThreeMinHeight, unset);

  // .content {
  //   display: flex;
  //   // grid-template-columns: 15% 74% 11%;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 0 1.5rem;
  //   transition: all 0.9s;
  //   height: var(--alt-header-size);

  //   .left {
  //     display: flex;
  //     align-items: center;
  //     gap: 1rem;
  //   }

  //   .menuLeft {
  //     display: flex;
  //     align-items: center;
  //     gap: 2rem;

  //     .logoMenu {
  //       padding: 1rem;
  //       width: 250px;
  //       text-align: center;

  //       a {
  //         img {
  //           // @include imgw;
  //           max-width: 100%;
  //           max-height: 55px;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }

  //   .mainMenu {
  //     display: flex;
  //     width: 100%;
  //     justify-content: flex-end;
  //     gap: 2rem;

  //     svg {
  //       color: var(--yellow-color);
  //       cursor: pointer;
  //       font-size: 2.5rem;

  //       transition: all 0.35s ease;

  //       &:hover {
  //         color: var(--yellow-color);
  //       }
  //     }
  //     .subMenuGridItemButton {
  //       background-color: green;
  //       border-radius: .5rem;

  //       padding: 1rem;
  //     }

  //     .subMenuGridItemButton:last-child {
  //       background-color: red;
  //     }

  //     .subMenuGridItem, .subMenuGridItemButton {
  //       display: flex;
  //       width: auto;
  //       height: 1.6rem;
  //       gap: 0.2rem;

  //       svg {
  //         color: var(--white);
  //         height: 100%;
  //         width: 100%;
  //       }

  //       img {
  //         height: 100%;
  //         width: 100%;
  //       }

  //       a {
  //         display: flex;
  //         align-items: center;
  //         justify-content: space-between;
  //         font-weight: normal;
  //         font-size: 1.3rem;
  //         white-space: nowrap;

  //         border-radius: 0.5rem;
  //         cursor: pointer;

  //         //width: 100%;
  //         width: fit-content;
  //         transition: all 0.35s ease;

  //         svg {
  //           color: transparent;
  //           font-size: 3rem;
  //         }

  //         &:hover {
  //           background-color: var(--secondary-color);

  //           svg {
  //             color: var(--yellow-color);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .dashboarWrap {
  //     display: flex;
  //     gap: 1rem;
  //     align-items: center;
  //     justify-content: flex-start;

  //     margin-left: 1rem;

  //     button {
  //       background-color: var(--color-red);
  //       border: 0;
  //       border-radius: 1rem;

  //       color: var(--white);
  //       cursor: pointer;

  //       font-size: 1.4rem;
  //       font-weight: var(--font-bold-light);

  //       overflow: hidden;
  //       padding: .8rem 2rem;

  //       text-transform: capitalize;
  //       transition: all .3s;
  //       text-align: center;

  //       // width: 50%;
  //     }

  //     .items {
  //       text-align: right;

  //       .small {
  //         font-size: 10px;
  //         color: var(--white);
  //         margin-bottom: 4px;
  //         display: block;
  //       }

  //       h6 {
  //         font-weight: var(--font-bold-light);
  //         color: var(--white);
  //         font-size: 14px;
  //         line-height: 110%;
  //       }

  //       .button {
  //         padding: 8px 20px 10px;
  //         font-size: 14px;
  //         font-weight: var(--font-bold-light);
  //         display: inline-block;
  //         text-transform: capitalize;
  //         transition: all 0.3s;
  //         text-align: center;
  //         background: var(--button-one);
  //         position: relative;
  //         overflow: hidden;
  //         border-radius: 10px;
  //         z-index: 1;

  //         &::before {
  //           position: absolute;
  //           content: "";
  //           top: 0;
  //           left: 0;
  //           height: 100%;
  //           width: 0px;
  //           transition: all 0.4s;
  //         }

  //         &:hover::before {
  //           width: 100%;
  //         }

  //         span {
  //           z-index: 1;
  //           position: relative;
  //           transition: all 0.4s;
  //         }

  //         &::before {
  //           background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
  //         }
  //       }

  //       .customDropdown {
  //         .customDropdownUser {
  //           width: 30px;
  //           height: 30px;
  //           border-radius: 50%;
  //           text-align: center;
  //           line-height: 33px;
  //           background: var(--social-box);

  //           .icons {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             justify-content: center;

  //             width: 30px;
  //             height: 30px;
  //             border-radius: 50%;
  //             text-align: center;
  //             line-height: 33px;
  //             background: var(--social-box);
  //             position: relative;

  //             svg {
  //               color: var(--white);
  //               font-size: 2rem;
  //             }
  //           }
  //         }
  //       }
  //     }

  //     .dashboarSocial {
  //       display: flex;
  //       align-items: center;
  //       gap: 10px;

  //       .icons {
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         justify-content: center;

  //         width: 30px;
  //         height: 30px;
  //         border-radius: 50%;
  //         text-align: center;
  //         line-height: 33px;
  //         background: var(--social-box);
  //         position: relative;

  //         svg {
  //           color: #1877f2;
  //           font-size: 2rem;
  //         }

  //         .count {
  //           width: 10px;
  //           height: 10px;
  //           text-align: center;
  //           line-height: 10px;
  //           background: var(--button-one);
  //           color: var(--white);
  //           display: block;
  //           border-radius: 50%;
  //           font-size: 8px;
  //           position: absolute;
  //           top: 0;
  //           right: 0;
  //         }
  //       }
  //     }
  //   }

  //   .menuBtnGrp {
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-end;
  //     // gap: 1.3rem;

  //     .languageWrap {
  //       position: relative;
  //       .nice-select,
  //       .selector {
  //         button {
  //           color: #fff;
  //           // padding: 7px 34px 9px 39px;
  //           &::after {
  //             border-bottom: 1px solid #fff;
  //             border-right: 1px solid #fff;
  //             height: 10px;
  //             margin-top: -6px;
  //             pointer-events: none;
  //             position: absolute;
  //             right: 16px;
  //             bottom: 50%;
  //             width: 10px;
  //             font-size: 14px;
  //           }
  //         }
  //         ul {
  //           background-color: rgba(245, 244, 255, 0.229);

  //           li {
  //             span {
  //               color: #fff;
  //               font-size: 14px;
  //             }
  //           }
  //         }
  //       }

  //       .flag {
  //         position: absolute;
  //         top: 4px;
  //         left: 0;
  //         width: 28px;
  //         height: 20px;
  //       }
  //     }

  //     button {
  //       position: relative;
  //       z-index: 1;

  //       display: inline-block;

  //       background: transparent;
  //       border: 0;
  //       border-radius: 1rem;

  //       color: var(--color-button);
  //       cursor: pointer;

  //       font-size: 1.4rem;
  //       font-weight: var(--font-bold-light);

  //       overflow: hidden;
  //       padding: 9px 40px 12px;

  //       text-transform: capitalize;
  //       transition: all 0.3s;
  //       text-align: center;

  //       &::before {
  //         position: absolute;
  //         content: "";
  //         top: 0;
  //         left: 0;
  //         height: 100%;
  //         width: 0px;
  //         transition: all 0.4s;
  //       }

  //       &:hover::before {
  //         width: 100%;
  //       }

  //       // &:hover {
  //       //     span {
  //       //     }
  //       // }

  //       span {
  //         z-index: 1;
  //         position: relative;
  //         transition: all 0.4s;
  //         display: block;
  //       }
  //     }

  //     .buttonNavigation {
  //       // color: var(--yellow-color);
  //       color: var(--black);
  //       background-color: var(--color-red);
  //       margin-right: .8rem;
  //       // padding: 0 1.5rem 0 0;

  //       // &::before {
  //       //     background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
  //       // }
  //     }

  //     .buttonNavigationTow {
  //       // background: var(--yellow-color);
  //       background-color: var(--color-red);

  //       // &::before {
  //       //     background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
  //       // }
  //     }
  //   }

  //   .languageWrap {
  //     position: relative;
  //     .nice-select,
  //     .selector {
  //       button {
  //         color: #fff;
  //         // padding: 7px 34px 9px 39px;
  //         &::after {
  //           border-bottom: 1px solid #fff;
  //           border-right: 1px solid #fff;
  //           height: 10px;
  //           margin-top: -6px;
  //           pointer-events: none;
  //           position: absolute;
  //           right: 16px;
  //           bottom: 50%;
  //           width: 10px;
  //           font-size: 14px;
  //         }
  //       }
  //       ul {
  //         background-color: rgba(245, 244, 255, 0.229);

  //         li {
  //           span {
  //             color: #fff;
  //             font-size: 14px;
  //           }
  //         }
  //       }
  //     }

  //     .flag {
  //       position: absolute;
  //       top: 4px;
  //       left: 0;
  //       width: 28px;
  //       height: 20px;
  //     }
  //   }
  // }
}

.balance {
  display: none;
}
.btns {
  display: none;
}
.headerMobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .header {
    min-height: unset;
  }

  .balance {
    display: flex;
    color: var(--white);
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;

    .balanceContainer {
      span {
        margin-left: 1rem;
      }
    }

    span {
      color: var(--color-red);
    }

    svg {
      color: var(--white);
    }

    .link {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .subMenuGridItemButton {
      display: flex;
      width: auto;
      padding: 0 1rem;
      height: 2.3rem;
      gap: 0.2rem;
      background-color: var(--color-red);
      border-radius: 0.6rem;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        font-weight: normal;
        font-size: 1.3rem;
        white-space: nowrap;

        border-radius: 0.5rem;
        cursor: default;

        //width: 100%;
        width: fit-content;
        transition: all 0.35s ease;

        svg {
          color: transparent;
          font-size: 3rem;
        }

        small {
          color: var(--color-red);
          font-size: 1.3rem;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
    padding: 1rem 0;

    button {
      position: relative;
      z-index: 1;
      min-width: 5.6rem;
      display: inline-block;

      background: var(--background-color-button);
      border: 0;
      border-radius: 1rem;

      color: var(--color-button);
      cursor: pointer;

      font-size: 1.4rem;
      font-weight: var(--font-bold-light);

      overflow: hidden;
      padding: 6px 25px 8px;

      text-transform: capitalize;
      transition: all 0.3s;
      text-align: center;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 0px;
        transition: all 0.4s;
      }

      &:hover::before {
        width: 100%;
      }

      span {
        z-index: 1;
        position: relative;
        transition: all 0.4s;
        display: block;
        min-width: 5.6rem;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .balance {
    gap: 3rem;
    .balanceContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-weight: bold;

      span {
        font-weight: normal;
        margin-left: 0 !important;
      }
    }
  }
}
