.popupsModal {
    :global(.MuiPaper-root) {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: var(--border-radius);
        background: transparent;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // overflow: hidden;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .popupsContainer {
            width: 100%;
            height: 100%;
            // display: flex;
            display: grid;
            grid-template-columns: repeat(4, 20%);
            grid-template-rows: 100%;
            // grid-auto-flow: dense;
            overflow: hidden;
            // flex-direction: row;
            align-items: center;
            justify-content: center;
            //gap: 2rem;
            padding: 0rem 3rem;
            justify-items: end;

            .popup {
                display: flex;
                flex-direction: column;
                background-color: white;
                // max-height: calc(100vh - 10rem);
                // height: calc(100vh - 300px);
                max-width: 400px;
                height: fit-content;

                .popupHeader {
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 1rem;
                    background: var(--header);
                    color: white;
                    font-weight: var(--font-bold-light);
                    align-items: center;

                    .announce,
                    .notShow {
                        display: flex;
                        gap: 1rem;
                        font-size: 1.4rem;
                        align-items: center;

                        > span {
                            font-size: 12px;
                        }
                    }

                    .notShow {
                        cursor: pointer;
                    }
                }

                .popupBody {
                    height: auto;
                    width: 100%;
                    display: contents;

                    > img {
                        aspect-ratio: 500/500;
                        object-fit: fill;
                        height: 100%;
                        width: 100%;
                    }
                }

                .popupFooter {
                    background: var(--header);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    height: 63px;
                    padding: 1rem;
                    align-items: center;

                    > button {
                        border: none;
                        background: var(--yellow-color);
                        cursor: pointer;
                        padding: 1rem;
                        border-radius: var(--border-radius);
                        font-weight: var(--font-bold-light);
                    }
                }
            }

            .emptyPopup {
                z-index: -1;
                pointer-events: none;
            }
        }

        .buttonContainer {
            width: 100%;
            // height: 100%;
            display: grid;
            grid-template-columns: repeat(4, 20%);
            grid-template-rows: 100%;
            // overflow: hidden;
            align-items: center;
            justify-content: center;
            padding: 0rem 3rem;

            .buttonWrapper {
                display: flex;
                justify-content: flex-end;

                .closeAll {
                    display: flex;
                    gap: 1rem;
                    background: var(--header);
                    padding: 1rem;
                    border: none;
                    color: var(--color-white, white);
                    margin-top: 13rem;
                    cursor: pointer;
                    align-self: start;
                    width: fit-content;
                    // margin-left: 1.5rem;
                }
            }
        }
    }
}

// @media only screen and (max-width: 1500px){
//     .popup{
//         width: calc(100vh - 400px);
//     }
// }

@media only screen and (max-width: 1150px) {
    .popupsModal {
        :global(.css-hz1bth-MuiDialog-container) {
            height: 100%;
        }

        .content {
            .buttonContainer {
                display: grid;
                justify-content: center;
                grid-template-columns: 40%;
                justify-items: end;
                padding: 0rem;

                div {
                    grid-area: 1 / 1;
                }
            }

            .popupsContainer {
                display: grid;
                grid-template-columns: 40%;
                justify-content: center;
                justify-items: end;
                padding: 0rem;

                .popup {
                    grid-area: 1 / 1;
                    width: 400px;

                    .popupBody {
                        max-width: 400px;
                        // width: 320px;
                        // height: 385px;
                        display: block;
                    }

                    div {
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .closeAll {
                    margin-top: 10rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .popupsModal {
        padding: 3rem 1rem;
        height: 100%;

        .content {
            .buttonContainer {
                grid-template-rows: none;
                grid-auto-flow: dense;
                // grid-template-columns: 54%;
                grid-template-columns: 100%;
                justify-content: center;
                overflow: visible;
                justify-items: center;
                max-width: 400px;

                div {
                    grid-area: 1 / 1;
                    // width: 400px;
                    width: 100%;
                }

                .buttonWrapper {
                    .closeAll {
                        margin-top: 0;
                    }
                }
            }

            .popupsContainer {
                //align-items: center;

                grid-template-rows: none;
                grid-auto-flow: dense;
                // grid-template-columns: 54%;
                grid-template-columns: 100%;
                justify-content: center;
                overflow: visible;
                justify-items: center;

                .popup {
                    height: 100%;
                    width: 100%;
                    max-height: 36rem;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    max-width: 400px;
                }
            }
        }
    }
}
